.exp-line-font{
    font-size: 22px;
}
.exp-line-style{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;
}
.exp-font-size{
    font-size: 22px;
    margin: 1rem 0;
}
.exp-grid-skill{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 1rem 0;
}
.exp-line{
    flex-grow: 1;
    height: 1px;
    background-color: #7E7E81;
    margin: 1rem 0;
}

@media (max-width: 768px) {    
    .exp-line-font{
        font-size: 20px;
    }
    .exp-font-size{
        font-size: 20px;
    }
  }
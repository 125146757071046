.project-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: #111111;
    
  }

.project-title-style{
  font-size: 22px;
  margin: 1rem 0;
  display: flex;
  justify-content: center;
}
.project-subtitle-style{
  font-size: 22px;
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  color: #0FFF9D;

}
.project-des-style{
  font-size: 22px;

}
.link-style{
  font-size: 22px;
  color: #0FFF9D;
  margin-top: 1rem;
  cursor: pointer,
}
.link-container{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 1.4rem;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
@media (max-width: 768px) {
  .project-title-style{
      font-size: 20px;

  }
  .project-subtitle-style{
      font-size: 20px;
  }
  .project-des-style{
      font-size: 20px;
  }
  .link-style{
    font-size: 20px;
  }
  .link-container{
    grid-template-columns: 1fr;
  }
}
html, body {
    height: 100%; /* 确保 html 和 body 的高度为 100% */
    margin: 0; /* 去掉默认的 margin */
    background-color: #111111; /* 设置背景颜色 */
    color: white; /* 如果需要，设置字体颜色 */
}
.archivo-black-regular {
    font-family: "Archivo Black", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

.rubik-normal {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}